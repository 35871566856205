import React, { useEffect, useRef } from 'react';
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap"
import RequiredIcon from "../../../../../../components/RequiredIcon";
import InputMask from "react-input-mask";
import { IActionResult, IAppState } from '../../../../../../redux/storeTypes';
import { connect, useDispatch } from 'react-redux';
import { AccountDetails, Order, PaymentCardTypeEnum, PaymentType, PaymentTypeEnum } from '../../../../../../models/Order';
import { calculateOrderAction, clearBankName, getBankName, updateOrderDetailsAction, UPDATE_ORDER_DETAILS } from '../../../../../../redux/actions/orderManagement';
import ECheckHelpIcon from '../../../../../../components/ECheckHelpIcon';
import { PaymentCardType } from '../../../../../../utils/binLookup';
import { BusinessTypeEnum, Client, PaymentChannel, SubMerchantAcceptedCardTypeEnum, Terminal } from '../../../../../../models/Client';
import { isInvalidCardExpiration } from '../../../../../../utils/globalCalculations';
import store from '../../../../../../redux/store';
import { RedirectRequest } from '../../../../../../models/RedirectIntegration';
import { UniformFees } from '../../../../../../utils/assessFees';

export interface IPaymentDetails {
    paymentTypeLabel: string,
    selectPaymentTypeOptionLabel: string,
    cardholderNameLabel: string,
    cardholderNamePlaceholder: string,
    cardholderNameRequired: string,
    cardTypeLabel: string,
    cardTypeRequired: string,
    cardNumberLabel: string,
    cardNumberRequired: string,
    expirationDateLabel: string,
    expirationDateRequired: string,
    cvvLabel: string,
    cvvRequired: string,
    nameOnAccountLabel: string,
    nameOnAccountPlaceholder: string,
    nameOnAccountRequired: string,
    bankAccountNumberLabel: string,
    bankAccountNumberRequired: string,
    confirmBankAccountNumberLabel: string,
    confirmBankAccountNumberRequired: string,
    bankRoutingNumberLabel: string,
    bankRoutingNumberRequired: string,
    bankNameLabel: string,
    eCheckDisclaimer: string,
    creditCardPaymentType: string,
    eCheckPaymentType: string,
    paypalPaymentType: string,
    terminalPaymentType: string,
    order: Order,
    accountDetails: AccountDetails,
    isSaving: boolean,
    validationErrors: any,
    bankName: string,
    client?: Client,
    paymentChannel?: PaymentChannel,
    actionResult: IActionResult,
    fullStoryMask: boolean,
    margin: string,
    padding: string,
    redirectRequest: RedirectRequest,
}

export const PAYMENT_TYPE_LABEL = 'Payment type';
export const SELECT_PAYMENT_TYPE_OPTION_LABEL = 'Select Payment type';
export const CARDHOLDER_NAME_LABEL = 'Cardholder name';
export const CARDHOLDER_NAME_PLACEHOLDER = 'Enter Cardholder name';
export const CARDHOLDER_NAME_REQUIRED = 'Please enter the Cardholder’s name.';
export const CARD_TYPE_LABEL = 'Card type';
export const CARD_TYPE_REQUIRED = 'Please use an accepted Card type.';
export const CARD_NUMBER_LABEL = 'Card number';
export const CARD_NUMBER_REQUIRED = 'Please enter a valid 12-16 digit Card number.';
export const EXPIRATION_DATE_LABEL = 'Expiration date';
export const EXPIRATION_DATE_REQUIRED = 'Please enter the Expiration date.';
export const CVV_LABEL = 'CVV';
export const CVV_REQUIRED = 'Please enter the CVV number.';
export const NAME_ON_ACCOUNT_LABEL = 'Name on account';
export const NAME_ON_ACCOUNT_PLACEHOLDER = 'Enter Name on account';
export const NAME_ON_ACCOUNT_REQUIRED = 'Please enter the Name on account.';
export const BANK_ACCOUNT_NUMBER_LABEL = 'Bank account number';
export const BANK_ACCOUNT_NUMBER_REQUIRED = 'Please enter the Bank account number.';
export const CONFIRM_BANK_ACCOUNT_NUMBER_LABEL = 'Confirm bank account number';
export const CONFIRM_BANK_ACCOUNT_NUMBER_REQUIRED = 'Please enter the Bank account number.';
export const BANK_ROUTING_NUMBER_LABEL = 'Bank routing number';
export const BANK_ROUTING_NUMBER_REQUIRED = 'Please enter a valid Bank routing number.';
export const BANK_NAME_LABEL = 'Bank name';
export const ECHECK_DISCLAIMER = '';
export const CREDITCARDPAYMENTTYPE = 'Credit/Debit Card';
export const ECHECKPAYMENTTYPE = 'eCheck';
export const PAYPALPAYMENTTYPE = 'PayPal';
export const TERMINALPAYMENTTYPE = 'Terminal';
export const MARGIN = '0px';
export const PADDING = '0px';
export const FULL_STORY_MASK = false;

const PaymentDetails = ({ paymentTypeLabel, selectPaymentTypeOptionLabel, fullStoryMask,
    cardholderNameLabel, cardholderNamePlaceholder, cardholderNameRequired, cardTypeLabel, cardTypeRequired, cardNumberLabel, cardNumberRequired, expirationDateLabel, expirationDateRequired, cvvLabel, cvvRequired,
    nameOnAccountLabel, nameOnAccountPlaceholder, nameOnAccountRequired, bankAccountNumberLabel, bankAccountNumberRequired, confirmBankAccountNumberLabel, confirmBankAccountNumberRequired, bankRoutingNumberLabel, bankRoutingNumberRequired, bankNameLabel,
    eCheckDisclaimer, creditCardPaymentType, eCheckPaymentType, paypalPaymentType, terminalPaymentType, order, accountDetails, isSaving, validationErrors, bankName, client, paymentChannel, actionResult, margin, padding, redirectRequest
    }: IPaymentDetails) => {
    const actionToken = "PaymentDetails";
    const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.Unknown);
    const [showCVV, setShowCVV] = useState<boolean>(false);
    const [showCardNumber, setShowCardNumber] = useState<boolean>(false);
    const [touched, setTouched] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [cardType, setCardType] = useState("");
    const [cardTypeEnum, setCardTypeEnum] = useState<PaymentCardTypeEnum>(0);
    const [cardTypeInvalid, setCardTypeInvalid] = useState(false);
    const [invalidCardType, setInvalidCardType] = useState<string>('');
    const cardNumber = useRef<HTMLInputElement | null>(null);
    const previousCardNumber = useRef<string>("");
    const [cardExpirationInvalid, setCardExpirationInvalid] = useState<boolean>(false);
    const [cardExpirationMessage, setCardExpirationMessage] = useState<string>('');
    const [bankAccountNumber, setBankAccountNumber] = useState<string>('');
    const [terminals, setTerminals] = useState<Array<Terminal> | undefined>();
    const [defaultTerminal, setDefaultTerminal] = useState("");
    const [kioskMode, setKioskMode] = useState(false);

    if (!paymentTypeLabel) {
        paymentTypeLabel = PAYMENT_TYPE_LABEL;
    }

    if (!selectPaymentTypeOptionLabel) {
        selectPaymentTypeOptionLabel = SELECT_PAYMENT_TYPE_OPTION_LABEL;
    }

    if (!cardholderNameLabel) {
        cardholderNameLabel = CARDHOLDER_NAME_LABEL;
    }

    if (!cardholderNamePlaceholder) {
        cardholderNamePlaceholder = CARDHOLDER_NAME_PLACEHOLDER;
    }

    if (!cardholderNameRequired) {
        cardholderNameRequired = CARDHOLDER_NAME_REQUIRED;
    }

    if (!cardTypeLabel) {
        cardTypeLabel = CARD_TYPE_LABEL;
    }
    
    if (!cardTypeRequired) {
        cardTypeRequired = CARD_TYPE_REQUIRED;
    }

    if (!cardNumberLabel) {
        cardNumberLabel = CARD_NUMBER_LABEL;
    }

    if (!cardNumberRequired) {
        cardNumberRequired = CARD_NUMBER_REQUIRED;
    }

    if (!expirationDateLabel) {
        expirationDateLabel = EXPIRATION_DATE_LABEL;
    }

    if (!expirationDateRequired) {
        expirationDateRequired = EXPIRATION_DATE_REQUIRED;
    }

    if (!cvvLabel) {
        cvvLabel = CVV_LABEL;
    }

    if (!cvvRequired) {
        cvvRequired = CVV_REQUIRED;
    }

    if (!nameOnAccountLabel) {
        nameOnAccountLabel = NAME_ON_ACCOUNT_LABEL;
    }

    if (!nameOnAccountPlaceholder) {
        nameOnAccountPlaceholder = NAME_ON_ACCOUNT_PLACEHOLDER;
    }

    if (!nameOnAccountRequired) {
        nameOnAccountRequired = NAME_ON_ACCOUNT_REQUIRED;
    }

    if (!bankAccountNumberLabel) {
        bankAccountNumberLabel = BANK_ACCOUNT_NUMBER_LABEL;
    }

    if (!bankAccountNumberRequired) {
        bankAccountNumberRequired = BANK_ACCOUNT_NUMBER_REQUIRED;
    }

    if (!confirmBankAccountNumberLabel) {
        confirmBankAccountNumberLabel = CONFIRM_BANK_ACCOUNT_NUMBER_LABEL;
    }

    if (!confirmBankAccountNumberRequired) {
        confirmBankAccountNumberRequired = CONFIRM_BANK_ACCOUNT_NUMBER_REQUIRED;
    }

    if (!bankRoutingNumberLabel) {
        bankRoutingNumberLabel = BANK_ROUTING_NUMBER_LABEL;
    }

    if (!bankRoutingNumberRequired) {
        bankRoutingNumberRequired = BANK_ROUTING_NUMBER_REQUIRED;
    }

    if (!bankNameLabel) {
        bankNameLabel = BANK_NAME_LABEL;
    }

    if (!eCheckDisclaimer) {
        eCheckDisclaimer = ECHECK_DISCLAIMER;
    }

    if (!creditCardPaymentType) {
        creditCardPaymentType = CREDITCARDPAYMENTTYPE;
    }

    if (!eCheckPaymentType) {
        eCheckPaymentType = ECHECKPAYMENTTYPE;
    }

    if (!paypalPaymentType) {
        paypalPaymentType = PAYPALPAYMENTTYPE;
    }

    if (!terminalPaymentType) {
        terminalPaymentType = TERMINALPAYMENTTYPE;
    }

    if (!margin) {
        margin = MARGIN;
    }

    if (!padding) {
        padding = PADDING;
    }

    fullStoryMask = fullStoryMask || FULL_STORY_MASK;

    const bankAccountValidationMessage = () => {
        if (validationErrors?.eCheckAccountNumber_match) {
            return validationErrors.eCheckAccountNumber_match;
        } else if (validationErrors?.eCheckAccountNumber_required) {
            return bankAccountNumberRequired;
        } else {
            return '';
        }
    }

    const confirmBankAccountValidationMessage = () => {
        if (validationErrors?.confirm_eCheckAccountNumber_match) {
            return validationErrors.confirm_eCheckAccountNumber_match;
        } else if (validationErrors?.eCheckAccountNumber_required) {
            return confirmBankAccountNumberRequired;
        } else {
            return '';
        }
    }

    const handleRoutingNumberOnChange = (e: any) => {
        const val = e.target.value;
        if (val.length === 9) {
            dispatch(getBankName(val));
        } else {
            dispatch(clearBankName());
        }
        setTouched(true);
    }

    const checkCardExpiry = (e: any) => {
        const cardExpiry = e.target.value;
        const checkDateFormat = /^[0-9\/\s]{5}$/
        const isValid = checkDateFormat.test(e.target.value)
        if (isValid) {
            const checkValidation = isInvalidCardExpiration(cardExpiry)
            if (!checkValidation) {
                setCardExpirationInvalid(false)
            } else {
                setCardExpirationInvalid(true)
                setCardExpirationMessage(checkValidation)
            }
        }
    }

    const handleOnBlurBankAccount = (e: any) => {
        if (order) {
            order.eCheckAccountNumber = bankAccountNumber
            dispatch(updateOrderDetailsAction(order, actionToken));
        }
    }

    const renderECheck = () => {
        return (
            <>
            <Row>
                <Col sm={12}>
                    <Form.Group controlId="eCheckAccountHolderName">
                        <Form.Label><RequiredIcon />{nameOnAccountLabel}</Form.Label>
                        <Form.Control type="input" required maxLength={100} placeholder={nameOnAccountPlaceholder} disabled={isSaving} defaultValue={accountDetails?.accountCustomerName || order?.nameOnCard}/>
                        <Form.Control.Feedback type="invalid">{nameOnAccountRequired}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="eCheckAccountNumber" key={paymentType}>
                        <Form.Label><RequiredIcon />{bankAccountNumberLabel}<ECheckHelpIcon /></Form.Label>
                        <Form.Control type="input" disabled={isSaving} value={bankAccountNumber} onChange={(e) => setBankAccountNumber(e.target.value)} pattern="^\d*$" maxLength={17} isInvalid={!!validationErrors?.eCheckAccountNumber_match || !!validationErrors?.eCheckAccountNumber_required} className="fs-mask" onBlur={handleOnBlurBankAccount}/>
                        <Form.Control.Feedback type="invalid">{bankAccountValidationMessage()}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                    <Form.Group controlId="confirm_eCheckAccountNumber">
                        <Form.Label><RequiredIcon />{confirmBankAccountNumberLabel}</Form.Label>
                        <Form.Control type="input" disabled={isSaving} pattern="^\d*$" maxLength={17} isInvalid={!!validationErrors?.confirm_eCheckAccountNumber_match || !!validationErrors?.confirm_eCheckAccountNumber_required} className="fs-mask" />
                        <Form.Control.Feedback type="invalid">{confirmBankAccountValidationMessage()}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="eCheckRoutingNumber">
                        <Form.Label><RequiredIcon />{bankRoutingNumberLabel}<ECheckHelpIcon /></Form.Label>
                        <Form.Control type="input" required disabled={isSaving} pattern="^\d*$" minLength={9} maxLength={9} onChange={handleRoutingNumberOnChange} isInvalid={!bankName && touched} className="fs-mask" />
                        <Form.Control.Feedback type="invalid">{bankRoutingNumberRequired}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                    <Form.Group controlId="eCheckBankName">
                        <Form.Label>{bankNameLabel}</Form.Label>
                        <Form.Control type="input" disabled={true} value={bankName} />
                    </Form.Group>
                </Col>
            </Row>
            {eCheckDisclaimer && eCheckDisclaimer.length > 0?
                <p className="mt-3 mb-0" style={{ color: '#8C8C8C' }}>{eCheckDisclaimer}</p>
            :
                <></>
            }
            </>
        );
    }

    useEffect(() => {
        if (actionResult && actionResult.type === UPDATE_ORDER_DETAILS) {
            dispatch(calculateOrderAction(actionToken));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    const doBinLookup = async (val: any) => {
        if (val.length >= 8) {
            PaymentCardType(val).then(result => {
                switch (result) {
                    case PaymentCardTypeEnum.AmericanExpressCredit:
                        if (cardTypeEnum !== PaymentCardTypeEnum.AmericanExpressCredit) {
                            setCardTypeEnum(PaymentCardTypeEnum.AmericanExpressCredit);
                            setCardType("American Express");
                            if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.AmericanExpress)) {
                                setCardTypeInvalid(false);
                            } else {
                                setInvalidCardType("American Express is not accepted.");
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    case PaymentCardTypeEnum.AmericanExpressDebit:
                        if (cardTypeEnum !== PaymentCardTypeEnum.AmericanExpressDebit) {
                            setCardTypeEnum(PaymentCardTypeEnum.AmericanExpressDebit);
                            setCardType("American Express - Debit");
                            if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.AmericanExpress)) {
                                setCardTypeInvalid(false);
                            } else {
                                setInvalidCardType("American Express - Debit is not accepted.");
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    case PaymentCardTypeEnum.DiscoverCredit:
                        if (cardTypeEnum !== PaymentCardTypeEnum.DiscoverCredit) {
                            setCardTypeEnum(PaymentCardTypeEnum.DiscoverCredit);
                            setCardType("Discover");
                            if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Discover)) {
                                setCardTypeInvalid(false);
                            } else {
                                setInvalidCardType("Discover is not accepted.");
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    case PaymentCardTypeEnum.DiscoverDebit:
                        if (cardTypeEnum !== PaymentCardTypeEnum.DiscoverDebit) {
                            setCardTypeEnum(PaymentCardTypeEnum.DiscoverDebit);
                            setCardType("Discover - Debit");
                            if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Discover)) {
                                setCardTypeInvalid(false);
                            } else {
                                setInvalidCardType("Discover - Debit is not accepted.");
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    case PaymentCardTypeEnum.MastercardCredit:
                        if (cardTypeEnum !== PaymentCardTypeEnum.MastercardCredit) {
                            setCardTypeEnum(PaymentCardTypeEnum.MastercardCredit);
                            setCardType("Mastercard");
                            if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.MasterCard)) {
                                setCardTypeInvalid(false);
                            } else {
                                setInvalidCardType("Mastercard is not accepted.");
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    case PaymentCardTypeEnum.MastercardDebit:
                        if (cardTypeEnum !== PaymentCardTypeEnum.MastercardDebit) {
                            setCardTypeEnum(PaymentCardTypeEnum.MastercardDebit);
                            setCardType("Mastercard - Debit");
                            if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.MasterCard)) {
                                setCardTypeInvalid(false);
                            } else {
                                setInvalidCardType("Mastercard - Debit is not accepted.");
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    case PaymentCardTypeEnum.VisaCredit:
                        if (cardTypeEnum !== PaymentCardTypeEnum.VisaCredit) {
                            setCardTypeEnum(PaymentCardTypeEnum.VisaCredit);
                            setCardType("Visa");
                            if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Visa)) {
                                setCardTypeInvalid(false);
                            } else {
                                setInvalidCardType("Visa is not accepted.");
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    case PaymentCardTypeEnum.VisaDebit:
                        if (cardTypeEnum !== PaymentCardTypeEnum.VisaDebit) {
                            setCardTypeEnum(PaymentCardTypeEnum.VisaDebit);
                            setCardType("Visa - Debit");
                            if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Visa)) {
                                setCardTypeInvalid(false);
                            } else {
                                setInvalidCardType("Visa - Debit is not accepted.");
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    default:
                        setCardType("Other");
                        setCardTypeEnum(PaymentCardTypeEnum.Unknown);
                        setCardTypeInvalid(true);
                        break;
                }
            });
        } else {
            setCardType("");
            setCardTypeEnum(PaymentCardTypeEnum.Unknown);
            setCardTypeInvalid(false);
        }
    };

    const handleOnChangeCardNumber = (e: any) => {
        let val = e.target.value;
        if (val.length >= 8) {
            if (previousCardNumber.current.substring(0, 8) !== cardNumber?.current?.value.substring(0, 8)) {
                previousCardNumber.current = val;
                doBinLookup(val);
            } else {
                if (!cardTypeInvalid) {
                    if (order && order.cardType !== PaymentCardTypeEnum.Unknown && (order.cardType as number * 1) !== (cardTypeEnum as number * 1) && cardNumber?.current?.value.length! === 16) {
                        order.paymentType = PaymentType.CreditCard;
                        order.cardType = cardTypeEnum;
                        dispatch(updateOrderDetailsAction(order, actionToken));
                    }
                }
            }
        } else {
            previousCardNumber.current = "";
            setCardType("");
            setCardTypeEnum(0);
            setCardTypeInvalid(false);
        }
    }

    const handleOnPasteCardNumber = (e: any) => {
        let val = e.clipboardData.getData("text");
        if (val.length >= 8) {
            doBinLookup(val);
        } else {
            setCardType("");
            setCardTypeEnum(0);
            setCardTypeInvalid(false);
        }
    }

    const handleOnBlurCardName = (e: any) => {
        if (cardNumber.current) {
            let val = cardNumber.current.value;
            if (val.length >= 8 && !cardType) doBinLookup(val);
        }
    }

    const handleCardTypeChange = (e: any) => {
        if (e.relatedTarget instanceof Node && !e.currentTarget.contains(e.relatedTarget)) {
            if (!cardTypeInvalid) {
                if (order && (order.cardType as number * 1) !== (cardTypeEnum as number * 1)) {
                    order.paymentType = PaymentType.CreditCard;
                    order.cardType = cardTypeEnum;
                    if (order.cardType && order.amount > 0) dispatch(updateOrderDetailsAction(order, actionToken));
                }
            }            
        }
    }

    const renderCreditCard = () => {
        return (
            <div onMouseLeave={handleCardTypeChange} onBlur={handleCardTypeChange}>
                <Row>
                    <Col md={12}>
                        <Form.Group controlId="nameOnCard">
                            <Form.Label><RequiredIcon />{cardholderNameLabel}</Form.Label>
                            <Form.Control type="input" required maxLength={50} placeholder={cardholderNamePlaceholder} disabled={isSaving} defaultValue={accountDetails?.accountCustomerName || order?.nameOnCard} onBlur={handleOnBlurCardName} />
                            <Form.Control.Feedback type="invalid">{cardholderNameRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="cardType">
                            <Form.Label><RequiredIcon />{cardTypeLabel}</Form.Label>
                            <Form.Control type="input" required disabled={isSaving} value={cardType} placeholder={"Displayed upon card number entry"} isInvalid={cardTypeInvalid} readOnly tabIndex={-1} ></Form.Control>
                            <Form.Control.Feedback type="invalid">{cardTypeRequired} {invalidCardType}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="cardNumber">
                            <Form.Label><RequiredIcon />{cardNumberLabel}</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type={showCardNumber ? "text" : "password"} required minLength={12} maxLength={16} disabled={isSaving} className="password fs-mask" onChange={handleOnChangeCardNumber} onPaste={handleOnPasteCardNumber} ref={cardNumber} />
                                <InputGroup.Append>
                                    <InputGroup.Text onClick={() => setShowCardNumber(!showCardNumber)}>
                                        <FontAwesomeIcon icon={showCardNumber ? faEyeSlash : faEye} size="sm" />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">{cardNumberRequired}</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} sm={6}>
                        <Form.Group controlId="expirationDate">
                            <Form.Label><RequiredIcon />{expirationDateLabel}</Form.Label>
                            <InputMask id="expirationDate" name="expirationDate" required type="input" pattern="[0-9\/\s]{5}" mask="99/99" className={cardExpirationInvalid ? "customValidation" :"form-control"} onChange={checkCardExpiry} disabled={isSaving} />
                            <Form.Control.Feedback type="invalid">{expirationDateRequired}.</Form.Control.Feedback>
                        </Form.Group>
                        {cardExpirationInvalid &&
                            <Form.Group controlId="expirationDateMasked">
                                <Form.Control type="input" isInvalid={cardExpirationInvalid} style={{ display: 'none' }} required={cardExpirationInvalid} />
                                <Form.Control.Feedback type="invalid"> {cardExpirationMessage}</Form.Control.Feedback>
                            </Form.Group>
                        }
                    </Col>
                    <Col md={3} sm={6}>
                        <Form.Group controlId="cvv">
                            <Form.Label><RequiredIcon />{cvvLabel}</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type={showCVV ? "text" : "password"} required minLength={cardTypeEnum === PaymentCardTypeEnum.AmericanExpressCredit || cardTypeEnum === PaymentCardTypeEnum.AmericanExpressDebit ? 4 : 3} maxLength={cardTypeEnum === PaymentCardTypeEnum.AmericanExpressCredit || cardTypeEnum === PaymentCardTypeEnum.AmericanExpressDebit ? 4 : 3} disabled={isSaving} className="password fs-mask" />
                                <InputGroup.Append>
                                    <InputGroup.Text onClick={() => setShowCVV(!showCVV)}>
                                        <FontAwesomeIcon icon={showCVV ? faEyeSlash : faEye} size="sm" />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">{cvvRequired}</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        );
    }

    const renderCardTypes = () => {
        let options = [];
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.AmericanExpress)) {
            options.push(<option key={1} value={PaymentCardTypeEnum.AmericanExpressCredit}>American Express</option>);
            options.push(<option key={2} value={PaymentCardTypeEnum.AmericanExpressDebit}>American Express - Debit</option>);
        }
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Discover)) {
            options.push(<option key={3} value={PaymentCardTypeEnum.DiscoverCredit}>Discover</option>);
            options.push(<option key={4} value={PaymentCardTypeEnum.DiscoverDebit}>Discover - Debit</option>);
        }
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.MasterCard)) {
            options.push(<option key={5} value={PaymentCardTypeEnum.MastercardCredit}>Mastercard</option>);
            options.push(<option key={6} value={PaymentCardTypeEnum.MastercardDebit}>Mastercard - Debit</option>);
        }
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Visa)) {
            options.push(<option key={7} value={PaymentCardTypeEnum.VisaCredit}>Visa</option>);
            options.push(<option key={8} value={PaymentCardTypeEnum.VisaDebit}>Visa - Debit</option>);
        }
        return ( <> {options} </> );
    }

    const handleTerminalCardTypeChange = (e: any) => {
        order.cardType = e.currentTarget.value;
        dispatch(updateOrderDetailsAction(order, actionToken));
    }

    const handleTerminalLaneIdChange = (e: any) => {
        dispatch(updateOrderDetailsAction(order, actionToken));
    }

    const renderTerminal = () => {
        return (
            <div onBlur={handleCardTypeChange}>
                <Row>
                    {!kioskMode && <Col md={6} sm={12}>
                        <Form.Group controlId="cardType">
                            <Form.Label><RequiredIcon />Card type</Form.Label>
                            <Form.Control as="select" required disabled={isSaving} onChange={handleTerminalCardTypeChange}>
                                <option value="">Select card type</option>
                                {renderCardTypes()}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Please select the Card type.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>}
                    <Col md={6} sm={12} hidden={kioskMode}>
                        <Form.Group controlId="terminalLaneId" key={defaultTerminal}>
                            <Form.Label><RequiredIcon />Terminal</Form.Label>
                            <Form.Control as="select" type="input" required disabled={isSaving} defaultValue={defaultTerminal} onChange={handleTerminalLaneIdChange}>
                                <option value="">Select terminal name</option>
                                {
                                    terminals?.map((terminal: any) => (<option key={terminal.msbId} value={`${terminal.msbId}|${terminal.laneId}`}>{terminal.name}</option>))
                                }
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Please select a Terminal name.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        );
    }

    const renderPaymentMethod = () => {
        if (paymentType === PaymentType.CreditCard) {
            return (renderCreditCard());
        } else if (paymentType === PaymentType.ECheck) {
            return (renderECheck());
        } else if (paymentType === PaymentType.Terminal) {
            return (renderTerminal());
        } else {
            return (<></>)
        }
    }

    const handlePaymentTypeChange = async (_paymentType: PaymentType) => {
        if (order && _paymentType === PaymentType.ECheck) {
            order.paymentType = _paymentType;
            order.cardType = PaymentCardTypeEnum.ElectronicCheck;
            order.isCardPresent = false;
            order.convenienceFee = 0.00;
            setTouched(false);
            dispatch(clearBankName());
            dispatch(updateOrderDetailsAction(order, actionToken));
        }

        if (order && _paymentType === PaymentType.CreditCard) {
            order.paymentType = _paymentType;
            order.cardType = PaymentCardTypeEnum.Unknown;
            order.isCardPresent = false;
            order.convenienceFee = 0.00;
            setCardType("");
            setCardTypeEnum(0);
            setCardTypeInvalid(false);
            dispatch(updateOrderDetailsAction(order, actionToken));
        }

        if (order && _paymentType === PaymentType.PayPal) {
            order.paymentType = _paymentType;
            order.cardType = PaymentCardTypeEnum.PayPal;
            order.isCardPresent = false;
            order.convenienceFee = 0.00;
            setCardType("");
            setCardTypeEnum(0);
            setCardTypeInvalid(false);
            dispatch(updateOrderDetailsAction(order, actionToken));
        }

        if (order && _paymentType === PaymentType.Terminal) {
            order.paymentType = PaymentType.CreditCard;
            order.cardType = PaymentCardTypeEnum.Unknown;
            order.isCardPresent = true;
            order.convenienceFee = 0.00;
            if (!terminals && redirectRequest?.pos && redirectRequest?.terminalName) {
                const headers = new Headers();
                headers.append('Authorization', `Bearer ${store.getState().webAppSettings.bearerToken}`);
                const response = await fetch(`${store.getState().webAppSettings.baseApiUrl}/MSB_Client/api/v1/PaymentChannels/${paymentChannel?.msbId}`, { headers: headers });
                const pc = await response.json();
                const _terminals = pc.processors.find((p: any) => p.merchantProcessor?.businessType === BusinessTypeEnum.Retail)?.merchantProcessor?.terminals;
                setTerminals(_terminals);
                const _terminal = _terminals.find((t: any) => t.name === redirectRequest?.terminalName);
                if (_terminal) setDefaultTerminal(`${_terminal.msbId}|${_terminal.laneId}`);
                if (redirectRequest?.pos.toUpperCase() === "KIOSK") {
                    setKioskMode(true);
                    if (await UniformFees(paymentChannel?.msbId)) {
                        order.cardType = PaymentCardTypeEnum.VisaCredit;
                    }
                }
            }
            setCardType("");
            setCardTypeEnum(0);
            setCardTypeInvalid(false);

            dispatch(updateOrderDetailsAction(order, actionToken));
        }

        setPaymentType(_paymentType);
    }

    useEffect(() => {
        if (order && paymentType === PaymentType.Unknown) {
            if (paymentChannel?.terminalPaymentsEnabled && redirectRequest?.pos && redirectRequest?.terminalName) {                
                setPaymentType(PaymentType.Terminal);
                handlePaymentTypeChange(PaymentType.Terminal);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    return (
        <div style={{ margin: margin, padding: padding }} id='scrollToPaymentType' className={fullStoryMask ? "fs-mask" : ""}>
            {kioskMode && (order.cardType === PaymentCardTypeEnum.Unknown ? <h2>Kiosk Mode Operation - BLOCKED - Bad Fees</h2> : <h2>Kiosk Mode Operation</h2>)}
            <Row hidden={kioskMode}>
                <Form.Group controlId="paymentType">
                    <Form.Label><RequiredIcon />{paymentTypeLabel}</Form.Label>
                    <Form.Control as='select' required value={paymentType} onChange={(e: any) => handlePaymentTypeChange(parseInt(e.target.value) as PaymentType)} disabled={isSaving || !order} defaultValue={PaymentType.Terminal}>
                        <option key={0} value={PaymentType.Unknown}>{selectPaymentTypeOptionLabel}</option>
                        {paymentChannel?.manualEntryPaymentsEnabled && <option key={1} value={PaymentType.CreditCard}>{creditCardPaymentType}</option>}
                        {paymentChannel?.electronicCheckPaymentsEnabled && <option key={2} value={PaymentType.ECheck}>{eCheckPaymentType}</option>}
                        {paymentChannel?.payPalPaymentsEnabled && !(redirectRequest?.pos && paymentChannel?.terminalPaymentsEnabled) && <option key={3} value={PaymentType.PayPal}>{paypalPaymentType}</option>}
                        {paymentChannel?.terminalPaymentsEnabled && redirectRequest?.pos && redirectRequest?.terminalName && <option key={4} value={PaymentType.Terminal}>{terminalPaymentType}</option>}
                    </Form.Control>
                </Form.Group>
            </Row>
            {
                renderPaymentMethod()
            }             
         </div>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.orderManagement.isSaving,
        client: state.clients.client,
        paymentChannel: state.paymentChannelWebApplication.paymentChannel,
        accountDetails: state.orderManagement.accountDetails,
        order: state.orderManagement.order,
        validationErrors: state.orderManagement.validationErrors,
        actionResult: state.orderManagement.actionResult,
        bankName: state.orderManagement.bankName,
        errorMessage: state.orderManagement.errorMessage,
        redirectRequest: state.redirectIntegration.redirectRequest,
    };
};

export default connect(mapStateToProps)(PaymentDetails);